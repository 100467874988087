.link {
	height: 2em;
	display: block;
	color: inherit;
	text-decoration: none;
	width: 70%;
	padding: 12px 40px 0px 40px;
}

.link:hover {
	background-color: #fbfbfb;
}

.sub-link {
	height: 1.5em;
	display: block;
	color: inherit;
	text-decoration: none;
	width: 70%;
	padding: 8px 40px 0px 50px;
}

.sub-link:hover {
	background-color: #fbfbfb;
}

.menu-header {
	height: 2em;
	display: block;
	color: inherit;
	text-decoration: none;
	width: 70%;
	padding: 12px 40px 0px 40px;
	font-weight: bolder;
	font-size: 120%;
}
